import { Entity } from './Entity';

export class Lives extends Entity {
  constructor() {
    super({className:"lives"});
    this.lives = 3;
    this.setX(window.innerWidth / 2 -50);
    this.setY(window.innerHeight - 100); // -20
    this.refreshText();
  }

  removeLife() {
    this.lives--;
    this.refreshText();
    return this.lives
  }

  refreshText() {
    this.el.innerText = new Array(this.lives)
      .fill(`❤`)
      .join(' ');
  }
}
